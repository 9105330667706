import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import Video from "@bgea/kaltura-player/dist/KalturaPlayer"
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';

function Page({ pageContext }) {
  const page = pageContext;

  return (
    <Layout>
      <div>
      <section className="hero">
          <div id="hero-header">
            <h3 className="text-is-centered">
            FRANKLIN GRAHAM WILL JOIN BELIEVERS IN POLAND TO SHARE THE HOPE OF JESUS CHRIST AT THE GOD LOVES YOU TOUR.
            </h3>
            <h4>
            THE EVENT IS FREE. EVERYONE IS WELCOME
            </h4>
          </div>
          <div className="videoContainer" id="hero-video">
            <section className="video">
              <div className="flexVideo">
                <Video id="1_u7mxg7ok" />
              </div>
            </section>
          </div>
        </section>
        <div className="home-content">
          <MDXRenderer>{page.body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
      }
      body
    }
  }
`;

export const Head = ({
  data: {
    mdx: {
      frontmatter: { title },
    },
  },
}) => {
  return <SEO title={title} />;
};
